import '../styles/OtpPage.css';
import React, {useState, useRef } from 'react';
import {
  makeStyles,
  Typography,
  TextField,
  InputAdornment,
  Button
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import commonStyles from '../utils/CommonMakeStyles';
import WelcomeMessage from '../components/WelcomeMessage';
import dataManager from '../utils/DataManager';
import constants from '../constants';
import { post } from '../utils/Communication';
import sentryManager from '../utils/SentryManager';
import WorkFlow from '../components/WorkFlow';

const useStyles = makeStyles(theme => ({
  otpFormContainer: {
    marginTop: '1.5em'
  },
}))

function OtpPage(props) {

  const commonStyle = commonStyles();
  const classes = useStyles();

  const paths = dataManager.getPaths();
  const history = useHistory();
  const location = useLocation();

  // eslint-disable-next-line
  const [mobileNumber, setMobileNumber] = useState(dataManager.data.mobileNumber);
  const [resendVisible, changeResendVisibility] = useState(false);
  const [isOtpInvalid, changeisOtpInvalid] = useState(false)
  const otpInputRef = useRef(null);

  const editMobileNum = (event) => {
    history.push(paths.login);
  }

  const submitOtp = (event) => {
    event.preventDefault();
    const dataToSend = {
      phonenumber: mobileNumber,
      otp: otpInputRef.current.value,
      reason: 9,
    }
    post(constants.endpoints.validateOtp, dataToSend)
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          response.json().then(res => {
            sentryManager.captureMessage(
              "Error with otp submission: " + JSON.stringify(res)
            )
          }).catch(err => {
            sentryManager.captureMessage(
              "Error with otp submission - status: " + response.status 
              + ' Data: ' + JSON.stringify(dataToSend)
            )
          })
          changeisOtpInvalid(true)
          // alert('Unable to validate OTP. Try again with the right OTP.')
        }
      })
      .then(result => {
        if (!result)
          return;
        let data = dataManager.getData();
        data.fromOtpPage = result;
        const hour = 1000 * 60 * 60 * 1;
        const authTokenLocalStr = {
          validity: Date.now() + (hour * 12),
          token: result.token
        }

        const authTokenSessionStr = {
          validity: Date.now() + (hour * 24),
          token: result.token
        }

        sentryManager.setTag('token', result.token);
        dataManager.storeToSessionStorage('authToken', authTokenSessionStr)
        dataManager.storeToLocalStorage('authToken', authTokenLocalStr)
        let pathToPush = paths.home;
        const state = location.state || null;
        if (state && state.replaceWith)
          pathToPush = state.replaceWith;
        history.push(pathToPush);
      })
      .catch((err)=> {
        sentryManager.captureMessage("Otp submission code error: " + err)
        // alert('There was some error with the OTP submission. Try again.')
      })

  }

  const checkOtpValid = () => {

  }

  const resendOtp = (event) =>{
    let data = dataManager.getData();
    let mobileNumber = data.mobileNumber;
    if (!mobileNumber)
      return;
    changeisOtpInvalid(false)
    sentryManager.setUser(mobileNumber);
    const dataToSend = {
      phonenumber: mobileNumber,
      reason: 9
    }
    post(constants.endpoints.getOtp, dataToSend)
      .then(response=> {
        if (response.status === 200)
          return response.json();
        else {
          sentryManager.captureMessage(
            'Error Fetching otp - status: ' +  response.status +
            ' Data ' + JSON.stringify(dataToSend)
          )
          return response.json();
        }
      })
      .then(result => {
        let data = dataManager.getData();
        data.fromLandingPage = result;
        data.mobileNumber = mobileNumber;
        showHideResend()
      })
      .catch((err)=> {
        alert('Error fetching OTP. Please check phone number registered with you doctor and try again.')
      })
  }
  let interValtimer = 30
  const showHideResend = () =>{
    changeResendVisibility(false);
    setCounter(interValtimer)
  }

  const [counter, setCounter] = React.useState(interValtimer);
  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      if (counter === 0)
        changeResendVisibility(true)
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <div className={"land-padding main-page-wrapper"}>
      <WelcomeMessage/>
      <div
        className={classes.otpFormContainer}
      >
        <Typography 
          className={'verification head'} variant="body1" 
          display="block" 
        >
          Verification
        </Typography>
        <Typography variant="body2" className={commonStyle.lightGrey}>
          Enter OTP code sent to your number
        </Typography>
        <form 
          onSubmit={submitOtp}
          className={'otp-form'}
        >   
          <TextField
            disabled
            onClick={editMobileNum}
            value={mobileNumber}
            InputProps={{
              id: "userMobileNo",
              endAdornment: (
                <InputAdornment className={'user phone'} position='end'>
                  <EditIcon />
                </InputAdornment>
              ),
            }}
          />
          <div>
            <TextField
              inputProps={{
                id: 'userOtpInput',
                inputMode: 'numeric',
                maxLength: 6,
              }}
              className={'otp otp-field'}
              onChange={checkOtpValid}
              inputRef={otpInputRef}
              label="6 Digit OTP"
              autoFocus={true}
            />
          </div>
          {
            resendVisible && (
            <div className='resend_otp_cnt text right'>
              Didn't get the code?
              <span className='resend_otp' onClick={resendOtp}>RESEND</span>
            </div>)
          }
          {!resendVisible && (
          <div className='resend_otp_cnt text right'>
            Resend OTP in
          <span className='resend_otp' >{counter} Secs</span>
          </div>)}
          {
            isOtpInvalid && <Typography align="center" color="error">
              Please enter valid OTP
          </Typography>
          }
          <div>
            <Button
              className={"doxper-blue-btn"}
              variant="contained"
              type="submit"
            >
              Login
            </Button>
          </div>
          <div>
          </div>

        </form>
      </div>
      <WorkFlow />
    </div>
  )
}

export default OtpPage;
