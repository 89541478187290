import '../styles/DeviceTestingPage.css';
import iosPermissionStep1 from '../images/ios-permission-step-1.png';
import iosPermissionStep2 from '../images/ios-permission-step-2.png';
import iosPermissionStep3 from '../images/ios-permission-step-3.png';
import ios13step1 from '../images/ios-13-step-1.jpeg';
import ios13step2 from '../images/ios-13-step-2.jpeg';
import chromeDesktopStep1 from '../images/chrome-desktop-step-1.png';
import chromeDesktopStep2 from '../images/chrome-desktop-step-2.png';
import safariDesktopStep1 from '../images/safari-desktop-step-1.png';
import safariDesktopStep2 from '../images/safari-desktop-step-2.png';
import chromeMobileStep1 from '../images/chrome-mobile-step-1.png';
import chromeMobileStep2 from '../images/chrome-mobile-step-2.png';
import chromeMobileStep3 from '../images/chrome-mobile-step-3.png';
import firefoxDesktopStep1 from '../images/firefox-desktop-step-1.png';

import React, {useState, useEffect} from 'react';
import {
  Card,
  CardContent,
  Typography,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  StepContent,
  CardActions,
  Button,
  Link,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import streamManager from '../utils/MediaStreamManager';
import sentryManager from '../utils/SentryManager';
import videoTrackChecker from '../utils/VideoTrackChecker';
import audioTrackChecker from '../utils/AudioTrackChecker';
import constants from '../constants';
import WhichBrowser from 'which-browser';
import dataManager from '../utils/DataManager';
import { getStepsToPerform } from '../utils/DeviceTestingHelper';

let BROWSER_INFO = new WhichBrowser(navigator.userAgent);

function DeviceTestingPage(props) {

  const {
    onGotStream,
    onHideDeviceTest,
    isStandalone,
    isReconnect = false
  } = props

  const testType = isStandalone ? 'standalone' : 'before-checkin'
  const paths = dataManager.getPaths();
  const history = useHistory();

  const [activeStep, setActiveStep] = useState(0);
  const [allStepsComplete, setAllStepsComplete] = useState(false);
  const [activeStream, setActiveStream] = useState(null);

  useEffect(()=> {
    window.history.pushState(null, null);
    window.history.pushState(null, null);
  },[])

  const [steps, setSteps] = useState(getStepsToPerform({
    isStandalone: isStandalone,
    isReconnect: isReconnect
  }));

  useEffect(()=> {
    let isMounted = true;
    streamManager.getStream({
      audio: true,
      video: {
        facingMode: 'user'
      }
    }).then(stream => {
      if (isMounted) {
        setActiveStream(stream);
        markStepCompletion(true, false);
        setActiveStep(prevStep => prevStep + 1);
      }
    }, err => {
      if (isMounted) {
        markStepCompletion(false, true);
        setSteps(prevStep => {
          prevStep[activeStep].showLoader = false;
          prevStep[activeStep].content = 
            getPermissionErrorContent(err)
          return [...prevStep];
        })
      }
      streamManager.handleError(err, false);

    })
    return () => {
      isMounted = false;
      setActiveStream(stream => {
        if (stream && stream.constructor === MediaStream)
          stream.getTracks().forEach(track => track.stop())
      })
    }
  // eslint-disable-next-line
  },[])

  useEffect(() => {
    const incompleteSteps = steps.filter(step => !step.completed);
    if (incompleteSteps.length === 0) {
      setAllStepsComplete(true);
    }
  }, [steps])

  useEffect(()=> {
    let isMounted = {state: true}
    if (activeStep === steps.length - 1) {
      if (!isStandalone) {
        markStepCompletion(true, false);
      }
      else {
        sentryManager.msgWithScope(
          'User Successfully Completed all the Tests',
          'info',
          'devicetest',
          testType
        )
        setSteps(prevSteps => {
          prevSteps[activeStep].label = 'Completed Tests Successfully'
          prevSteps[activeStep].showLoader = false;
          prevSteps[activeStep].content = 'All Tests Completed successfully'
          return [...prevSteps]
        })
        markStepCompletion(true, false);
        // arbitary large number since we already reached end of steps.
        setActiveStep(99);
      }
    } else {
      switch(activeStep) {
        case 1: getVideoStats(isMounted); break;
        case 2: getAudioStats(isMounted); break;
        default: break;
      }
    }
    return () => isMounted.state= false;
  // eslint-disable-next-line
  }, [activeStep])

  const getVideoStats = (isMounted) => {
    videoTrackChecker.startTest(activeStream, isStandalone, isMounted)
      .then(()=> {
        const videoFrameStats = videoTrackChecker.frameStats;
        // console.log('Video Stats ', videoFrameStats)
        sentryManager.msgWithScope(
          'User Video Frame Stats - frameStats: ' 
          + JSON.stringify(videoFrameStats),
          'info',
          'video-test',
          testType
        )
        if (videoFrameStats.numFrames <= 2 ) {
          // TODO: prevent users from proceeding further after we have some
          // more data.
          setActiveStream(stream => {
            stream.getTracks().forEach(track => track.stop())
            return null;
          })
          markStepCompletion(false, true);
          setSteps(prevSteps => {
            prevSteps[activeStep].showLoader = false;
            prevSteps[activeStep].content = 
              streamManager.getErrorMessageToDisplay({name: 'NoVideoFrames'})
            return [...prevSteps]
          })
          sentryManager.msgWithScope(
            'User Stopped from joining room - frameStats: ' 
            + JSON.stringify(videoFrameStats) ,
            'info',
            'video-test',
            testType
          )
        } else {
          markStepCompletion(true, false);
          setActiveStep(prevStep => prevStep + 1);
        }
      })
      .catch(err => {
        markStepCompletion(false, true);
        setSteps(prevSteps => {
          prevSteps[activeStep].showLoader = false;
          prevSteps[activeStep].content = err.message;
          return [...prevSteps]
        })
        sentryManager.msgWithScope(
          'Error getting video track stats: ' + JSON.stringify(err),
          'info',
          'device-test',
          testType
        )
      })
  }

  const getAudioStats = (isMounted) => {
    audioTrackChecker.startTest(activeStream, isStandalone, isMounted)
      .then(() => {
        const msgArr = audioTrackChecker.messagesArr;
        // console.log(msgArr);
        sendAudioMsgToSentry(msgArr, `User Audio/Mic Stats`);
        if (msgArr.info.indexOf('Active input detected') !== -1) {
          markStepCompletion(true, false);
          setActiveStep(prevStep => prevStep + 1);
        } else {
          setSteps(prevSteps => {
            prevSteps[activeStep].showLoader = false;
            prevSteps[activeStep].content = 
              streamManager.getErrorMessageToDisplay({name: 'NoOutputFromMic'})
            return [...prevSteps]
          })
          // TODO: prevent users from proceeding further after we have some
          // more data.
          markStepCompletion(false, true);
          sendAudioMsgToSentry(msgArr, `User stopped from joining room`);
        }
      })
      .catch(err => {
        markStepCompletion(false, true);
        setSteps(prevSteps => {
          prevSteps[activeStep].showLoader = false;
          prevSteps[activeStep].content = err.message;
          return [...prevSteps]
        })
        sentryManager.msgWithScope(
          'Error getting audio track stats: ' + JSON.stringify(err),
          'info',
          'device-test',
          testType
        )
      })

  }

  const sendAudioMsgToSentry = (msgArr, msg) => {
    sentryManager.msgWithScope(
      `${msg} - Audio stats values: ` +
      JSON.stringify(msgArr),
      'info',
      `audio-test`,
      testType
    )
  }

  const markStepCompletion = (isComplete, hasError) => {
    setSteps(prevSteps => {
      prevSteps[activeStep].completed = isComplete;
      prevSteps[activeStep].error = hasError;
      return [...prevSteps]
    });
  }

  useEffect(()=> {
    if (allStepsComplete) {
      setActiveStream(stream => {
        stream.getTracks().forEach(track => track.stop())
        return null;
      })
      if (!isStandalone) {
        streamManager.getStream()
          .then(stream => {
            onGotStream(stream)
            onHideDeviceTest();
          })
          .catch(err => {
            alert('Error getting media stream (tests failed): ', err.message);
            sentryManager.captureMessage(
                'Error getting media streams after test success - error: ' + err.name +
                ': ' + err.message
            );
          })
      }
    }
  // eslint-disable-next-line
  }, [allStepsComplete])

  const getPermissionErrorContent = (err) => {
    if (err.name === 'NotAllowedError') {
      switch(
        BROWSER_INFO.os.name.toLowerCase() + ' ' + 
        BROWSER_INFO.browser.name.toLowerCase()
      ) {
        case 'ios safari':
          return (
            <div>
              You need to enable camera and mic permissions on your browser. 
              Please enable the permissions and refresh the page to try again
              <br/><br/>
              {
                (parseInt(BROWSER_INFO.os.version.value.substr(0, 2)) >= 13) &&
                <>
                  If you are unsure about how to enable permissions then 
                  click&nbsp;
                  <Link href={constants.safariMobilePermissionUrl} 
                  target="_blank"
                  >
                    here
                  </Link> to know how to enable them. OR <br />
                  You can follow the steps below to enable them
                  <div
                    className={'ios-steps-container'}
                  >
                    <img
                      src={ios13step1}
                      alt="how-to-enable-permission-step-1">
                    </img>
                    <img
                      src={ios13step2}
                      alt="how-to-enable-permission-step-2">
                    </img>
                  </div>
                </>
              }
              {
                (parseInt(BROWSER_INFO.os.version.value.substr(0, 2)) < 13) &&
                <>
                  You can follow the steps below to enable them
                  <div
                    className={'ios-steps-container'}
                  >
                    <img
                      src={iosPermissionStep1}
                      alt="how-to-enable-permission-step-1">
                    </img>
                    <img
                      src={iosPermissionStep2}
                      alt="how-to-enable-permission-step-2">
                    </img>
                    <img
                      src={iosPermissionStep3}
                      alt="how-to-enable-permission-step-3">
                    </img>
                  </div>
                </>
              }
            </div>
          )
        case 'android chrome':
          return (
            <div>
              You need to enable camera and mic permissions on your browser.
              Please enable the permissions and refresh the page to try again
              <br /><br />
              If you are unsure about how to enable permissions then 
              click&nbsp;
              <Link href={constants.chromeMobilePermissionUrl} 
              target="_blank"
              >
                here
              </Link> to know how to enable them. OR <br />
              You can follow the steps below to enable them
              <div
                className={'ios-steps-container'}
              >
                <img
                  src={chromeMobileStep1}
                  alt="how-to-enable-permission-step-1">
                </img>
                <img
                  src={chromeMobileStep2}
                  alt="how-to-enable-permission-step-2">
                </img>
                <img
                  src={chromeMobileStep3}
                  alt="how-to-enable-permission-step-3">
                </img>
              </div>
            </div>
          )
        default: break;
      }

      switch (
        BROWSER_INFO.device.type.toLowerCase() + ' ' +
        BROWSER_INFO.browser.name.toLowerCase()
      ) {
        case 'desktop chrome':
          return (
            <div>
              You need to enable camera and mic permissions on your browser. 
              Please enable the permissions and refresh the page to try again
              <br/><br/>
              If you are unsure about how to enable permissions then 
              click&nbsp;
              <Link href={constants.chromeDesktopPermissionUrl} 
              target="_blank"
              >
                here
              </Link> to know how to enable them. OR <br />
              You can follow the steps below to enable them
              <div
                className={'ios-steps-container'}
              >
                <img
                  src={chromeDesktopStep1}
                  alt="how-to-enable-permission-step-1">
                </img>
                <img
                  src={chromeDesktopStep2}
                  alt="how-to-enable-permission-step-2">
                </img>
              </div>
            </div>
          )
        case 'desktop safari':
          return (
            <div>
              You need to enable camera and mic permissions on your browser.
              Please enable the permissions and refresh the page to try again
              <br /><br />
              If you are unsure about how to enable permissions then 
              click&nbsp;
              <Link href={constants.safariDesktopPermissionUrl} 
              target="_blank"
              >
                here
              </Link> to know how to enable them. OR <br />
              You can follow the steps below to enable them
              <div
                className={'ios-steps-container'}
              >
                <img
                  src={safariDesktopStep1}
                  alt="how-to-enable-permission-step-1">
                </img>
                <img
                  src={safariDesktopStep2}
                  alt="how-to-enable-permission-step-2">
                </img>
              </div>
            </div>
          )
        case 'desktop firefox':
          return (
            <div>
              You need to enable camera and mic permissions on your browser.
              Please enable the permissions and refresh the page to try again
              <br /><br />
              If you are unsure about how to enable permissions then 
              click&nbsp;
              <Link href={constants.firefoxDesktopPermissionUrl} 
              target="_blank"
              >
                here
              </Link> to know how to enable them. OR <br />
              You can follow the steps below to enable them
              <div
                className={'ios-steps-container'}
              >
                <img
                  src={firefoxDesktopStep1}
                  alt="how-to-enable-permission-step-1">
                </img>
              </div>
            </div>
          )
        default: break;
      }
    }
    return streamManager.getErrorMessageToDisplay(err);
  }

  const goHome = () => {
    history.push(paths.home);
  }

  return (
    <div 
      className="devicetesting-container"
    >
      <Card>
        <CardContent>
          <Typography className={'doxper blue'} variant="h5">
            Checking Device
          </Typography>
          <Stepper orientation="vertical" activeStep={activeStep}>
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel error={step.error}>
                  <Typography variant="body1">
                    {step.label + ' ' + step.extra}
                  </Typography>
                </StepLabel>
                <StepContent>
                  {
                    (step.showLoader && activeStep === index && !step.error) && 
                    <CircularProgress className={'doxper blue'} size={20} />
                  }
                  <span> {step.content}</span>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {
            isStandalone &&
            <CardActions>
              <Button onClick={goHome}>
                Back to Home Page
              </Button>
            </CardActions>
          }
        </CardContent>
      </Card>
    </div>
  )
}

export default DeviceTestingPage;
