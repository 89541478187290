
let apiUrl = ''
if (process.env.NODE_ENV === 'development')
    apiUrl = 'https://tele-doxper.ngrok.io';
else if (process.env.REACT_APP_BUILD_STAGE === 'true')
    apiUrl = 'https://e5cad7d5.ngrok.io';
else
    apiUrl = window.location.protocol + '//doxper.com'

    // apiUrl = apiUrl = window.location.protocol + '//doxper.com'

const constants = {
    appRootUrl: '/telemedicine/',
    SENTRY_DSN: 'https://0f1d9add8a6448399525908b190943d7@o326904.ingest.sentry.io/5190396',
    RAZOR_PAY_CHECKOUT: 'https://api.razorpay.com/v1/checkout/embedded',
    deviceTestTimeoutSec: 10,
    chromeMobilePermissionUrl: `https://support.google.com/chrome/answer/2693767?\
        co=GENIE.Platform%3DAndroid&hl=en`,
    safariMobilePermissionUrl: `https://stackoverflow.com/questions/48847388/how-\
        to-permanently-allow-usage-of-camera-on-trusted-websites-with-ios-\
        safari/57368061#57368061`,
    firefoxDesktopPermissionUrl: `https://support.mozilla.org/en-US/kb/how-\
        manage-your-camera-and-microphone-permissions#w_using-the-firefox-\
        2optionssf3preferencessf-menu-to-change-camera-and-microphone-permissions`,
    chromeDesktopPermissionUrl: `https://support.google.com/chrome/answer/2693767?\
        co=GENIE.Platform%3DDesktop&hl=en&oco=1`,
    safariDesktopPermissionUrl: `https://support.apple.com/en-in/guide/safari/ibrwe2159f50/mac`,
    checkinBeforeMin: 10,
    payBeforeMin: 10,
    slotLeewayMin: 30,
    maxSlotDaysToShow: 30,
    autoEndTimeSec: 120,
    sendFeedbackTimeSec: 1.5,
    apiUrl: apiUrl,
    endpoints: {
        getOtp: '/api/otp/send/?source=web_app',
        validateOtp: '/api/otp/validate/',
        getPatientInfo: '/api/v2/masterpatientdetails/',
        getAppointmentInfo: '/api/v2/appointment/',
        getConnectionToken: '/api/v1/appointment_room/',
        endRoom: '/tele_consult/room/',
        uploadContent: '/api/upload/',
        uploadLabReport: '/api/v2/labreports/',
        paymentDetails: '/tele_consult/payment_details/',
        razorpayOrderCreate: '/tele_consult/order_create/',
        razorpayCallback: '/tele_consult/order_callback/',
        razorpayCancel: '/tele_consult/order_cancel/',
        doctorsList: '/api/v2/self_booking_doctors/',
        getSlotsForTheDay: '/api/v3/slots/',
        feedback: '/api/v2/feedback/?source=patient_app',
        feeReceipt: '/tele_consult/tele_receipt/',
        labReports: 'api/v2/labreports/',
    }
}

export default constants;

