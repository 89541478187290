import '../styles/LandingPage.css';
import React, { useState, useRef, useEffect } from 'react';
import {
  makeStyles,
  Typography,
  TextField,
  Button,
  Link,
  Checkbox,
} from '@material-ui/core'
import {
  Autocomplete
} from '@material-ui/lab';
import { 
  useHistory,
  Redirect,
  Route,
} from 'react-router-dom';
import commonStyles from '../utils/CommonMakeStyles'
import WelcomeMessage from '../components/WelcomeMessage'
import { post } from '../utils/Communication';
import constants from '../constants';
import dataManager from '../utils/DataManager';
import { isSupported } from 'twilio-video'
import sentryManager from '../utils/SentryManager';
import WorkFlow from './WorkFlow';
import WhichBrowser from 'which-browser';
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile'


const useStyles = makeStyles(theme => ({
  mobileNoContainer: {
    marginTop: '1.5em'
  },
  mobileForm: {
    marginTop: '8px',
  },
  countryOption: {
    maxWidth: '180px',
    marginTop: '1em',
    '& input': {
      fontSize: '14px',
    },
    '& label': {
      fontSize: '13px',
    },
  },
  workingText: {
    color: "#00aeff",
    fontWeight: "bold",
  },
}))

function LandingPage(props) {

  const paths = dataManager.getPaths();
  const countries = dataManager.getCountryList();

  let history = useHistory();

  const commonStyle = commonStyles();
  const classes = useStyles();
  const [isvalidMobileNumber, setMobileNumValidity] = useState(true);
  const [mobileNumber, setMobileNumber] = useState('')
  const [mobileErrorText, setMobileErrorText] = useState('Invalid Mobile Number.')
  const mobileInputRef = useRef(null);
  const [tncChecked, changeTncCheckboxStatus] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(countries[1]);
  const [countryValInTextbox, setCountryValInTextBox] = useState('')
  const [countrySelected, setCountrySelected] = useState(true);
  const noCountryErrorText = 'Please select country';

  useEffect(() => {
    if (!selectedCountry)
      setCountrySelected(false)
    else
      setCountrySelected(true)
  },[selectedCountry])

  const onMobileSubmit = (event) => {
    event.preventDefault();
    if (isvalidMobileNumber && tncChecked) {
      const mobileNumber =
        '+' + selectedCountry.phone + mobileInputRef.current.value;
      sentryManager.setUser(mobileNumber);
      const dataToSend = {
        phonenumber: mobileNumber,
        reason: 9
      }
      post(constants.endpoints.getOtp, dataToSend)
        .then(response => {
          if (response.status === 200)
            return response.json();
          else {
            sentryManager.captureMessage(
              'Error Fetching otp - status: ' + response.status +
              ' Data ' + JSON.stringify(dataToSend)
            )
            setMobileErrorText('Number not found!')
            setMobileNumValidity(false);
            alert('Number not found\nYou can only use the same mobile number' + 
              ' on which your doctor has booked your appointment')
            return false
          }
        })
        .then(result => {
          if (result) {
            let data = dataManager.getData();
            data.fromLandingPage = result;
            data.mobileNumber = mobileNumber;
            const state = history.location.state || null;
            let stateToPush = {
              from: paths.login,
              replaceWith: paths.home,
            }
            if (state && state.matchPath &&
              state.matchPath === paths.doctorListingSpecific
            ) {
              stateToPush.replaceWith = state.from.pathname;
            }
            history.push(paths.otp, stateToPush);
          }
        })
        .catch((err) => {
          alert('Error Fetching OTP. Please try again');
          sentryManager.captureMessage(
            'Error Fetching otp - status: ' + JSON.stringify(err)
          );
        })
    }
  }

  const checkValidity = (event) => {
    if (!selectedCountry) {
      setCountrySelected(false);
      return;
    }
    const mobileNumber = mobileInputRef.current.value;
    const mobileNo = parsePhoneNumberFromString(
      '+' + selectedCountry.phone + mobileNumber
    );
    if (isNaN(mobileNumber) || !mobileNo || !mobileNo.isValid()) {
      if (mobileErrorText !== 'Invalid Mobile Number.')
        setMobileErrorText('Invalid Mobile Number.')
      setMobileNumValidity(false);
    }
    else
      setMobileNumValidity(true);
    setMobileNumber(mobileNumber)
  }
  const loadTncInNewTab = () => {
    let url = window.location.origin + paths.tnc
    window.open(url);
  }
  const handleTncCheckboxChange = () => {
    changeTncCheckboxStatus(!tncChecked)
  }

  let browserSupported = false
  let parser = null;

  try {
    parser = new WhichBrowser(navigator.userAgent)
  } catch (err) {
    sentryManager.captureMessage('UA parsing error : ' + JSON.stringify(err));
  }

  const browserName = parser && parser.browser && parser.browser.name;
  switch (browserName && browserName.toLowerCase()) {
    case 'chrome':
    case 'firefox':
    case 'safari':
    case 'samsung internet':
      if (isSupported) {
        browserSupported = true
      }
      break;
    default:
      browserSupported = false
  }

  const doxperVideoTitle = {
    color: "#00aeef",
    fontWeight: 'bold',
    marginBottom: '1em'
  }

  let authToken = dataManager.getFromLocalStorage('authToken')
  if (authToken) {
    authToken = JSON.parse(authToken)
    if (new Date(authToken.validity) > new Date()) {
      sentryManager.setTag('token', authToken.token);
      return (
        <Route
          render={() => {
            return (<Redirect
              to={{
                pathname: paths.home,
              }}
            />)
          }}
        />
      )
    }
  }

  if (browserSupported) {
    const isValidForOtp = tncChecked && mobileNumber && isvalidMobileNumber
    return (
      <React.Fragment>
        <div className={"land-padding main-page-wrapper"}>
          <WelcomeMessage />
          <div
            className={classes.mobileNoContainer}
          >
            <Typography variant="body1" display="block" gutterBottom>
              Login with Mobile Number
            </Typography>
            <Typography variant="body2" className={commonStyle.lightGrey}>
              Enter your mobile number we will send you an OTP to verify
            </Typography>

            <Autocomplete
              className={classes.countryOption}
              value={selectedCountry}
              getOptionLabel={(option) => option.label}
              onChange={(event, newValue) => {
                setSelectedCountry(newValue);
              }}
              inputValue={countryValInTextbox}
              onInputChange={(event, newInputValue) => {
                setCountryValInTextBox(newInputValue);
              }}
              options={countries}
              renderOption={(option) => (
                <span style={{fontSize: '14px'}}>
                  <span>{dataManager.countryToFlag(option.code)}</span>
                  &nbsp;{option.label} ({option.code}) +{option.phone}
                </span>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Country"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />

            <form
              onSubmit={onMobileSubmit}
              className={classes.mobileForm + ' mobile-form'}
            >
              <div>
                <TextField
                  inputProps={{
                    inputMode: 'tel'
                  }}
                  className={'mobile-input'}
                  inputRef={mobileInputRef}
                  onChange={checkValidity}
                  error={!isvalidMobileNumber || !countrySelected}
                  label="Mobile Number"
                  autoFocus={true}
                  helperText={
                    <>
                      {!isvalidMobileNumber &&
                        <span>
                          {mobileErrorText}
                        </span>
                      }
                      {!countrySelected &&
                        <span>
                          {noCountryErrorText}
                        </span>
                      }
                    </>
                  }
                />
              </div>
              <div className='tnc-option'>
                <Checkbox
                  className={'checkbox'}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onChange={handleTncCheckboxChange}
                />
                <span className={'vertical middle'}>
                  I accept
                </span>&nbsp;<span 
                  onClick={loadTncInNewTab} 
                  className='tnc_link vertical middle'>T&Cs</span>
              </div>
              <div>
                <Button
                  className={'send-otp-btn ' + (isValidForOtp ? "doxper-blue-btn" : '')}
                  variant="contained"
                  type="submit"
                  disabled={isValidForOtp ? false : true}
                >
                  Send OTP
              </Button>
              </div>

            </form>
          </div>
          <WorkFlow />
        </div>
      </React.Fragment>
    )
  } else {
    sentryManager.captureMessage("Unsupported browser:" + JSON.stringify(parser))
    return (
      <div className={"err-land-padding main-page-wrapper"}>
        <Typography variant="h5" style={doxperVideoTitle} component="h5">
          Doxper video Consultation
        </Typography>
        <Typography className={'not-supported head'} variant="body1" display="block">
          Sorry, this browser is not supported.
        </Typography>
        <Typography className={'not-supported info'} variant="body2">
          a) You need to use the latest version of
         <Link href="https://www.google.com/chrome/"> Chrome
          </Link>
          , <Link href="https://www.mozilla.org/en-US/firefox/new/">
            Firefox
          </Link> or <Link href="https://www.apple.com/safari/">
            Safari&nbsp;
          </Link>(Only for iPhone/Ipad/Mac).
        </Typography>
        <Typography className={'not-supported info'} variant="body2">
          b) If any one of the above browsers are already installed, then
          open the browser and copy paste this link in the address bar.
        </Typography>
        <Typography className={'not-supported info'} variant="body2">
          c) Contact us at <Link href="tel:+8882599599">
            8882599599
          </Link> if not able to access.
        </Typography>
      </div>
    )
  }

}

export default LandingPage;